var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"padding":"40px"}},[_c('v-row',{attrs:{"align":"center"}},[(_vm.logs)?_c('v-col',{staticStyle:{"background-color":"white","margin":"0","padding":"0 !important"},attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.logs,"single-expand":true,"expanded":_vm.expanded,"item-key":"ID","show-expand":"","hide-default-footer":"","items-per-page":-1},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Access")]),_c('v-spacer'),_c('v-text-field',{staticStyle:{"margin-right":"10px"},attrs:{"outlined":"","append-icon":"mdi-magnify","prepend-inner-icon":_vm.search !== '' ? 'mdi-close' : '',"hide-details":"","dense":"","autofocus":""},on:{"click:append":function($event){return _vm.handleGetData(0)},"click:prepend-inner":_vm.handleClear,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleGetData(0)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-btn',{on:{"click":_vm.handleGetData}},[_vm._v(" refrescar ")])],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('small',[_vm._v("Environments: ")]),_vm._l((_vm.handleGetEnvironments(item.Environments)),function(item){return _c('v-chip',{key:item.name,staticClass:"ma-2",attrs:{"color":"pink","label":"","outlined":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" "+_vm._s(item.icon)+" ")]),_vm._v(" "+_vm._s(item.name)+" ")],1)}),_c('br'),_c('small',[_vm._v("UserAgent: "),_c('b',[_vm._v(_vm._s(item.UserAgent))])]),_c('br'),_c('br')],2)]}},{key:"item.IP",fn:function(ref){
var item = ref.item;
return [_c('a',{on:{"click":function($event){return _vm.handleFilterBySelect(item.IP)}}},[_vm._v(_vm._s(item.IP))])]}},{key:"item.User",fn:function(ref){
var item = ref.item;
return [_c('a',{on:{"click":function($event){return _vm.handleFilterBySelect(item.User)}}},[_vm._v(_vm._s(item.User))]),(item.System || (item.Environments && item.Environments.indexOf('sys') >= 0))?_c('a',{staticStyle:{"margin-left":"5px"}},[_vm._v("[ "),_c('v-icon',{attrs:{"x-small":"","color":"orange"}},[_vm._v("mdi-alert")]),_vm._v(" ]")],1):_vm._e()]}},{key:"item.UserID",fn:function(ref){
var item = ref.item;
return [_c('a',{on:{"click":function($event){return _vm.handleFilterBySelect(item.UserID)}}},[_vm._v(_vm._s(item.UserID))])]}},{key:"item.UserAgentIcons",fn:function(ref){
var item = ref.item;
return _vm._l((item.UserAgentIcons),function(icon){return _c('v-tooltip',{key:icon,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(icon)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(icon.replace('mdi-', '')))])])})}}],null,false,3324847588)}),_c('pagination',{attrs:{"startIndex":_vm.startIndex,"itemsPerPage":_vm.itemsPerPage,"onGetData":_vm.handleGetData,"locale":_vm.locale,"count":_vm.count,"onChangeItemsPerPage":_vm.handleChangeItemsPerPage}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }