<template>
  <div style="padding:40px;">
    <v-row
      align="center"
    >
      <v-col 
        cols="12"
        v-if="logs"
        style="background-color: white; margin:0;padding:0 !important;"
      >
        <v-data-table
          :headers="headers"
          :items="logs"
          :single-expand="true"
          :expanded.sync="expanded"
          item-key="ID"
          show-expand
          hide-default-footer
          :items-per-page="-1"
          class="elevation-0"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Access</v-toolbar-title>
              <v-spacer/>
        <v-text-field
          outlined
          append-icon="mdi-magnify"
          :prepend-inner-icon="search !== '' ? 'mdi-close' : ''"
          hide-details
          dense
          style="margin-right:10px;"
          v-model="search"
          autofocus
          @click:append="handleGetData(0)"
          @click:prepend-inner="handleClear"
          v-on:keyup.enter="handleGetData(0)"
        />
              <v-spacer/>
              <v-btn @click="handleGetData">
                refrescar
              </v-btn>
            </v-toolbar>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <small>Environments: </small>
              <v-chip
                v-for="item of handleGetEnvironments(item.Environments)"
                :key="item.name"
                class="ma-2"
                color="pink"
                label
                outlined
              >
                <v-icon left>
                  {{ item.icon }}
                </v-icon>
                {{ item.name}}
              </v-chip>
              <br/>
              <small>UserAgent: <b>{{ item.UserAgent }}</b></small>
              <br/>
              <br/>
            </td>
          </template>
          <template v-slot:item.IP="{item}">
            <a @click="handleFilterBySelect(item.IP)">{{item.IP}}</a>
          </template>
          <template v-slot:item.User="{item}">
            <a @click="handleFilterBySelect(item.User)">{{item.User}}</a>
            <a v-if="item.System || (item.Environments && item.Environments.indexOf('sys') >= 0)" style="margin-left: 5px;">[ <v-icon x-small color="orange">mdi-alert</v-icon> ]</a>
          </template>
          <template v-slot:item.UserID="{item}">
            <a @click="handleFilterBySelect(item.UserID)">{{item.UserID}}</a>
          </template>
          <template v-slot:item.UserAgentIcons="{item}">
            <v-tooltip bottom
              v-for="icon of item.UserAgentIcons"
              :key="icon"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  {{icon}}
                </v-icon>
              </template>
              <span>{{icon.replace('mdi-', '')}}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      <pagination
        :startIndex="startIndex"
        :itemsPerPage="itemsPerPage"
        :onGetData="handleGetData"
        :locale="locale"
        :count="count"
        :onChangeItemsPerPage="handleChangeItemsPerPage"
      />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import api from '@/services/api'
import constants from '@/constants'
import Pagination from './Pagination'
export default {
  components: {
    Pagination,
  },
  data: () => ({
    logs: [],
    expanded: [],
    search: null,
    startIndex: 0,
    itemsPerPage: 10,
    count: 0,
  }),
  computed: {
    locale () {
      return 'es'
    },
    headers () {
      return [
        { text: 'User', sortable: false, value: 'User' },
        { text: 'UserID', sortable: false, align: 'center', value: 'UserID' },
        { text: 'IP', sortable: false, align: 'center', value: 'IP' },
        { text: 'Status', sortable: false, align: 'center', value: 'Status' },
        { text: '', sortable: false, align: 'left', value: 'UserAgentIcons' },
        { text: 'LMD', sortable: false, value: 'LMD' },
      ]
    },
  },
  mounted () {
    this.handleGetData()
  },
  methods: {
    handleClear () {
      this.search = null
      this.handleGetData()
    },
    convertDate (v) {
      const options = { weekday: 'long', day: 'numeric', month: 'numeric', year: 'numeric' }
      const date = new Date(v).toLocaleDateString(this.locale, options)
      const time = new Date(v).toLocaleTimeString(this.locale)
      const today = new Date().toLocaleDateString(this.locale, options)
      const isToday = date === today ? true : false 
      return `${isToday ? 'Hoy ' : '' }${date} ${time}`
    },
    handleFilterBySelect (v) {
      this.search = v
      this.handleGetData()
    },
    handleGetData (v) {
      if (v >= 0) this.startIndex = v
      this.logs = []
      const filter = { 
        search: this.search && this.search.length > 0 ? this.search : null,
        startIndex: this.startIndex, 
        itemsPerPage: this.itemsPerPage, 
      }
      api.put(this.environment, 'v1/system/logs/access', filter)
        .then(response => {
          this.count = response.data.count
          this.logs = response.data.data.map(item => {
            item.LMD = this.convertDate(item.LMD)
            item.UserAgentIcons = []
            if (item.UserAgent) {
              if (item.UserAgent.indexOf('Macintosh') >= 0) item.UserAgentIcons.push('mdi-apple')
              if (item.UserAgent.indexOf('iPhone') >= 0) item.UserAgentIcons.push('mdi-apple')
              if (item.UserAgent.indexOf('Windows') >= 0) item.UserAgentIcons.push('mdi-microsoft-windows')
              if (item.UserAgent.indexOf('Android') >= 0) item.UserAgentIcons.push('mdi-android')
              if (item.UserAgent.indexOf('SamsungBrowser') >= 0) item.UserAgentIcons.push('mdi-android')

              if (item.UserAgent.indexOf('Firefox') >= 0) item.UserAgentIcons.push('mdi-firefox')
              if (item.UserAgent.indexOf('Chrome') >= 0) item.UserAgentIcons.push('mdi-google-chrome')

              if (item.UserAgent.indexOf('Mobile') >= 0) item.UserAgentIcons.push('mdi-cellphone')
              if (item.UserAgent.indexOf('SamsungBrowser') >= 0) item.UserAgentIcons.push('mdi-tablet-cellphone')
            }
            return item
          })
        })
    },
    handleChangeItemsPerPage (v) {
      this.itemsPerPage = v
      this.handleGetData(0)
    },
    handleGetEnvironments (v) {
      const aux = JSON.parse(v)
      if (!aux) return
      return aux.map(item => {
        return constants.environments.filter(x => x.name === item).shift()
      }).filter(x => x)
    },
  },
}
</script>


